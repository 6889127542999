import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const validationSchemaEvent = yup.object({
  AQ0001: yup.number("Enter a number").required("Is required"),
  AQ0003: yup.number("Enter a number").required("Is required"),
  AQ0004: yup.number().required(),
  AQ0007: yup.number().required(),
  AQ0013: yup.number().required(),
  AQ0018: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0019: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0021: yup.number().required(),
});

function LeagueReportCaptain() {
  let navigate = useNavigate();
  const [todoAR, setTodoAR] = useState([]);
  const [submited, setSubmited] = useState(false);

  const [searchParams] = useSearchParams();
  const theARId = searchParams.get("id");

  const GetTodo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // Update salesforce.com/services/data/v21.0/sobjects to salesforce.com/services/data/v31.0/sobjects
    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
        theARId,
      requestOptions
    );
    const data = await response.json();
    try {
      setTodoAR(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetTodo();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const SaveResponse = async (theValue, theDisplay, theQuestion, theType) => {
    var theJSON = {
      Display_Value__c: theDisplay,
      EIR_Action_Question__c: theQuestion,
      EIR_Action_Todo__c: todoAR.Id,
      App_User__c: todoAR.App_Data_Manager__c,
    };

    if (theType === "Boolean") {
      if (theValue === "True") {
        theJSON.Value_as_Boolean__c = true;
      } else {
        theJSON.Value_as_Boolean__c = false;
      }
    }
    if (theType === "String") {
      theJSON.Value_as_String__c = theValue;
    }
    if (theType === "Number") {
      theJSON.Value_as_Number__c = theValue;
    }
    if (theType === "Id") {
      theJSON.Value_as_ID__c = theValue;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Response__c/",
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      //toast("Response created");
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateTodoAR = async () => {
    var theJSON = {
      Submit_Date__c: today,
      State__c: "Submitted",
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(theJSON, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
        todoAR.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("Action Report submited"))
      .then((result) => navigate("/home"))
      .catch((error) => console.log("error", error));
  };

  const today = new Date();

  const formikEvent = useFormik({
    initialValues: {
      AQ0001: "",
      AQ0003: "",
      AQ0004: "",
      AQ0007: "",
      AQ0013: "",
      AQ0018: "",
      AQ0019: "",
      AQ0021: "",
      AQ0022a: "",
      AQ0023: "",
    },
    validationSchema: validationSchemaEvent,
    onSubmit: (values) => {
      setSubmited(true);
      //alert(JSON.stringify(values, null, 2));
      SaveResponse(
        values.AQ0001,
        values.AQ0001,
        "a2D090000004OVDEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0003,
        values.AQ0003,
        "a2D090000004OVEEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0004,
        values.AQ0004,
        "a2D090000004OVNEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0007,
        values.AQ0007,
        "a2D090000004OVTEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0013,
        values.AQ0013,
        "a2D090000004OVYEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0018,
        values.AQ0018,
        "a2D090000004OVhEAM",
        "Number"
      );
      SaveResponse(
        values.AQ0019,
        values.AQ0019,
        "a2D090000004OVmEAM",
        "Number"
      );
      SaveResponse(
        values.AQ0021,
        values.AQ0021,
        "a2D090000004OVsEAM",
        "Number"
      );
      if (values.AQ0022a !== "") {
        SaveResponse(
          values.AQ0022a,
          values.AQ0022a,
          "a2D9N0000002omIUAQ",
          "Number"
        );
      }
      if (values.AQ0023 !== "") {
        SaveResponse(
          values.AQ0023,
          values.AQ0023,
          "a2D9N0000002omDUAQ",
          "Number"
        );
      }
      UpdateTodoAR();
    },
  });

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form onSubmit={formikEvent.handleSubmit}>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h4"
                  component="div"
                >
                  ACTION REPORT
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Time to track and add your activities to the data pool.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  This should take no more than 5 to 10 minutes to complete.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Thank you.
                </Typography>
                <Typography
                  sx={{ pt: 2, pl: 2 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  {todoAR.Title__c}
                </Typography>
                {/* AQ0003 - Select - Number - 0-9 */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q1: Since the last game day how many times did the team meet
                  up and train? Include football and other physical activities?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم مرة التقى فريقك و تدربوا؟ بما في ذلك
                  تدريبات كرة القدم والانشطة الأخرى
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0003-select-label">
                    Select / حددِ
                  </InputLabel>
                  <Select
                    labelId="AQ0003-select-label"
                    id="AQ0003"
                    label="Input / ادخِل"
                    name="AQ0003"
                    disabled={submited}
                    value={formikEvent.values.AQ0003 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0003 &&
                      Boolean(formikEvent.errors.AQ0003)
                    }
                    helperText={
                      formikEvent.touched.AQ0003 && formikEvent.errors.AQ0003
                    }
                  >
                    <MenuItem key="0" value="0">
                      0
                    </MenuItem>
                    <MenuItem key="1" value="1">
                      1
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      2
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      3
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      4
                    </MenuItem>
                    <MenuItem key="5" value="5">
                      5
                    </MenuItem>
                    <MenuItem key="6" value="6">
                      6
                    </MenuItem>
                    <MenuItem key="7" value="7">
                      7
                    </MenuItem>
                    <MenuItem key="8" value="8">
                      8
                    </MenuItem>
                    <MenuItem key="9" value="9">
                      9
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0004 - Select - Minutes */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q2: Since the last game day how many hours did the team spend
                  training together? Include football and other physical
                  activities?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم عدد الساعات التي قضاها افراد فريقك في
                  التدريب معاً؟ بما في ذلك تدريبات كرة القدم والانشطة الأخرى
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0004-select-label">
                    Select / حددِ
                  </InputLabel>
                  <Select
                    labelId="AQ0004-select-label"
                    id="AQ0004"
                    label="Duration (in minutes)"
                    name="AQ0004"
                    disabled={submited}
                    value={formikEvent.values.AQ0004 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0004 &&
                      Boolean(formikEvent.errors.AQ0004)
                    }
                    helperText={
                      formikEvent.touched.AQ0004 && formikEvent.errors.AQ0004
                    }
                  >
                    <MenuItem key="60" value="60">
                      1 hour / ساعة واحدة
                    </MenuItem>
                    <MenuItem key="90" value="90">
                      1 hour and 30 minutes / ساعة ونصف
                    </MenuItem>
                    <MenuItem key="120" value="120">
                      2 hours / ساعتين
                    </MenuItem>
                    <MenuItem key="150" value="150">
                      2 hours and 30 minutes / ساعتان و نصف
                    </MenuItem>
                    <MenuItem key="180" value="180">
                      3 hours / ثلاث ساعات
                    </MenuItem>
                    <MenuItem key="210" value="210">
                      3 hours and 30 minutes / ثلاث ساعات ونصف
                    </MenuItem>
                    <MenuItem key="240" value="240">
                      More / أكثر
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0007 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q3: Since the last game day, how many from outside the team
                  attended the team's training and physical activities?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم عدد الاشخاص من خارج فريقك الذين
                  شاركوا في تدريبات الفريق وأنشطته؟
                </Typography>
                <NiceTextField
                  id="AQ0007-number"
                  label="Input / ادخِل"
                  name="AQ0007"
                  value={formikEvent.values.AQ0007 || ""}
                  disabled={submited}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0007 &&
                    Boolean(formikEvent.errors.AQ0007)
                  }
                  helperText={
                    formikEvent.touched.AQ0007 && formikEvent.errors.AQ0007
                  }
                />
                {/* AQ0013 - Boolean */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q4: Since the last game day, how many community / Global Goals
                  events did the team host?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم عدد الفعاليات المجتمعية التي قام بها
                  فريقك لتحقيق أهداف التنمية المستدامة؟
                </Typography>
                <NiceTextField
                  id="AQ0013-number"
                  label="Input / ادخِل"
                  name="AQ0013"
                  value={formikEvent.values.AQ0013 || ""}
                  disabled={submited}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0013 &&
                    Boolean(formikEvent.errors.AQ0007)
                  }
                  helperText={
                    formikEvent.touched.AQ0013 && formikEvent.errors.AQ0013
                  }
                />
                {/* AQ0018 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q5: Since the last game day, how many people outside the team
                  took part in the team's community / Global Goal events?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم عدد الاشخاص من خارج فريقك الذين
                  شاركوا في الفعاليات المجتمعية للفريق لتحقيق أهداف التنمية
                  المستدامة؟
                </Typography>
                <NiceTextField
                  id="AQ0018-number"
                  label="Input / ادخِل"
                  name="AQ0018"
                  disabled={submited}
                  value={formikEvent.values.AQ0018 || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0018 &&
                    Boolean(formikEvent.errors.AQ0018)
                  }
                  helperText={
                    formikEvent.touched.AQ0018 && formikEvent.errors.AQ0018
                  }
                />
                {/* AQ0019 - Select - Minutes */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q6:Since the last game day, how many hours total did the team
                  spend on the community / Global Goal event(s)?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم عدد الساعات الكلية التي قضاها فريقك
                  في القيام بفعاليات مجتمعية لتحقيق أهداف التنمية المستدامة؟
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0019-select-label">
                    Select / حددِ
                  </InputLabel>
                  <Select
                    labelId="AQ0019-select-label"
                    id="AQ0019"
                    label="Duration (in minutes)"
                    name="AQ0019"
                    disabled={submited}
                    value={formikEvent.values.AQ0019 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0019 &&
                      Boolean(formikEvent.errors.AQ0019)
                    }
                    helperText={
                      formikEvent.touched.AQ0019 && formikEvent.errors.AQ0019
                    }
                  >
                    <MenuItem key="60" value="60">
                      1 hour / ساعة واحدة
                    </MenuItem>
                    <MenuItem key="90" value="90">
                      1 hour and 30 minutes / ساعة ونصف
                    </MenuItem>
                    <MenuItem key="120" value="120">
                      2 hours / ساعتين
                    </MenuItem>
                    <MenuItem key="150" value="150">
                      2 hours and 30 minutes / ساعتان و نصف
                    </MenuItem>
                    <MenuItem key="180" value="180">
                      3 hours / ثلاث ساعات
                    </MenuItem>
                    <MenuItem key="210" value="210">
                      3 hours and 30 minutes / ثلاث ساعات ونصف
                    </MenuItem>
                    <MenuItem key="240" value="240">
                      4 hours / أربع ساعات
                    </MenuItem>
                    <MenuItem key="300" value="300">
                      More / أكثر
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0021 - Select - NPS - 1-10 */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q7: From your perspective, how likely is it that the events
                  inspired others to act?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  من وجهة نظرك، ما مدى احتمالية أن تكون الأنشطة والفعاليات مصدر
                  الهام للآخرين؟
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0021-select-label">
                    Select / حددِ
                  </InputLabel>
                  <Select
                    labelId="AQ0021-select-label"
                    id="AQ0021"
                    label="Choose / اختارِ"
                    name="AQ0021"
                    disabled={submited}
                    value={formikEvent.values.AQ0021 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0021 &&
                      Boolean(formikEvent.errors.AQ0021)
                    }
                    helperText={
                      formikEvent.touched.AQ0021 && formikEvent.errors.AQ0021
                    }
                  >
                    <MenuItem key="1" value="1">
                      1 (lowest)
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      2
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      3
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      4
                    </MenuItem>
                    <MenuItem key="5" value="5">
                      5
                    </MenuItem>
                    <MenuItem key="6" value="6">
                      6
                    </MenuItem>
                    <MenuItem key="7" value="7">
                      7
                    </MenuItem>
                    <MenuItem key="8" value="8">
                      8
                    </MenuItem>
                    <MenuItem key="9" value="9">
                      9
                    </MenuItem>
                    <MenuItem key="10" value="10">
                      10 (highest)
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0001 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q8: How many social media followers does the team have today?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  كم عدد متابعي صفحة فريقك على وسائل التواصل الاجتماعي اليوم؟
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  إذا كان لدى فريقك عدة صفحات على وسائل التواصل الاجتماعي، فقم
                  بوضع عدد المتابعين لحساب واحد فقط (وهو نفس الحساب في المرة
                  الماضية)
                </Typography>
                <NiceTextField
                  id="AQ0001"
                  label="Input / ادخِل"
                  name="AQ0001"
                  placeholder="If the team has multiple social media profiles, add the number from only one profile (and the same you reported on the last time)."
                  value={formikEvent.values.AQ0001 || ""}
                  onChange={formikEvent.handleChange}
                  disabled={submited}
                  error={
                    formikEvent.touched.AQ0001 &&
                    Boolean(formikEvent.errors.AQ0001)
                  }
                  helperText={
                    formikEvent.touched.AQ0001 && formikEvent.errors.AQ0001
                  }
                />

                {/* AQ0022a - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q9 Since last game day how many NEW partnerships have you
                  created?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  منذ المباراة الماضية، كم عدد الشراكات الجديدة التي عقدها
                  فريقك؟
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  الشراكة قد تكون مع فريق آخر أو راعٍ أو شريك آخر داعم
                </Typography>
                <NiceTextField
                  id="AQ0022a-message"
                  label="Input / ادخِل"
                  name="AQ0022a"
                  disabled={submited}
                  placeholder="A partnership can be created with another team, a sponsor or another supporting partner."
                  value={formikEvent.values.AQ0022a || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0022a &&
                    Boolean(formikEvent.errors.AQ0022a)
                  }
                  helperText={
                    formikEvent.touched.AQ0022a && formikEvent.errors.AQ0022a
                  }
                />
                {/* AQ0023 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q10 How many fans did your team bring to the last game day?
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  align="right"
                  variant="body2"
                  component="div"
                >
                  كم عدد المشجعين الذي أحضرهم فريقك في المباراة الماضية؟
                </Typography>
                <NiceTextField
                  id="AQ0023-message"
                  label="Input / ادخِل"
                  name="AQ0023"
                  disabled={submited}
                  placeholder="Number of fans."
                  value={formikEvent.values.AQ0023 || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0023 &&
                    Boolean(formikEvent.errors.AQ0023)
                  }
                  helperText={
                    formikEvent.touched.AQ0023 && formikEvent.errors.AQ0023
                  }
                />
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button variant="contained" type="submit" disabled={submited}>
                  SUBMIT
                </Button>
                <Button
                  sx={{ pl: 4 }}
                  component={Link}
                  to={"/home"}
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default LeagueReportCaptain;
