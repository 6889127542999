import React, { useState, useEffect } from "react";
import { PublicLeagueLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from '@mui/material/Divider';
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import Avatar from "react-avatar";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import CardMedia from "@mui/material/CardMedia";
import { Link as MuiLink } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { format, parseJSON, parseISO } from "date-fns";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { Provider, LikeButton } from "@lyket/react";
import { toast } from "react-toastify";

// https://translation.googleapis.com/language/translate/v2?key=AIzaSyCIgO9_Oz8NJItmppkfoDX6D59IQniZedA&q=%D9%85%D9%86%20%D8%AE%D9%84%D8%A7%D9%84%20%D8%A7%D9%84%D8%B9%D9%85%D9%84%20%D9%88%D8%A7%D9%84%D9%84%D8%B9%D8%A8%20%D9%88%D8%A3%D9%86%20%D8%AA%D9%83%D9%88%D9%86%20%D9%82%D8%AF%D9%88%D8%A9%20%D9%85%D8%AB%D8%A7%D9%84%D9%8A%D8%A9%20%D9%84%D8%BA%D9%8A%D8%B1%D9%87%D8%A7%20%D9%85%D9%86%20%D8%A7%D9%84%D9%86%D8%B3%D8%A7%D8%A1%20%D9%84%D8%AA%D9%83%D9%88%D9%86%20%D9%85%D8%AB%D9%84%D9%86%D8%A7&source=ar&target=da
// https://github.com/kingRayhan/reactjs-visibility?tab=readme-ov-file
// https://date-fns.org/v3.3.1/docs/Time-Zones

const ImpactLeagueTheme = createTheme({
  typography: {
    fontFamily: "Public Sans"
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Public Sans"
        }
      }
    }
  }
});
const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const WhiteTypography = styled(Typography)({
  width: "95%",
  color: "#FFFFFF",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const NiceCheckboxFormControl = styled(FormControlLabel)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "0px",
});

const PublicLeague = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [showImpactLeagueHeader, setShowImpactLeagueHeader] = useState(true);
  const [open, setOpen] = useState();
  const [scheduleGameday, setScheduleGameday] = useState();
  const [scheduleTeam, setScheduleTeam] = useState();
  const [programRankings, setProgramRankings] = useState([]);
  const [voteRankings, setVoteRankings] = useState([]);
  const [userHasVoted, setUserHasVoted] = useState(false);
  const [votingState, setVotingState] = useState(false);
  const [programGames, setProgramGames] = useState([]);
  const [programRawGames, setProgramRawGames] = useState([]);
  const [programEvents, setProgramEvents] = useState([]);
  const [programNextEvents, setProgramNextEvents] = useState([]);
  const [programTeams, setProgramTeams] = useState([]);
  const [programActionTeam, setProgramActionTeam] = useState([]);
  const [activeLeague, setActiveLeague] = useState([]);
  const [showNavigation, setShowNavigation] = useState(false);
  const [publicStep, setPublicStep] = useState("PickLeague");
  const [program, setProgram] = useState();
  const [programName, setProgramName] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("total_p");
  const [teamvalue, setTeamvalue] = useState([]);
  const [teamAR_Q4_hours, setTeamAR_Q4_hours] = useState(0);
  const [teamAR_Q7_observers, setTeamAR_Q7_observers] = useState(0);
  const [teamAR_Q14_actions, setTeamAR_Q14_actions] = useState(0);
  const [teamAR_Q18_people, setTeamAR_Q18_people] = useState(0);
  const [programAR_Q4_hours, setProgramAR_Q4_hours] = useState(0);
  const [programAR_Q7_observers, setProgramAR_Q7_observers] = useState(0);
  const [programAR_Q14_actions, setProgramAR_Q14_actions] = useState(0);
  const [programAR_Q18_people, setProgramAR_Q18_people] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function getQuestionValue(rawData, theQuestion) {
    var scanData = rawData;
    var theValue = 0;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.eir_action_question__c === theQuestion) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    if (scanData.length === 1) {
      theValue = scanData[0].sum;
    }
    return theValue;
  }

  const FetchProgramActionReportTotals = async (Id) => {
    const theID = Id.substr(0, 15);
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/programActionReportTotals/" +
      theID;
    const response = await fetch(theURL);
    const data = await response.json();
    //console.log(theID);
    //console.log(data);
    try {
      setProgramAR_Q4_hours(getQuestionValue(data, "a2D090000004OVNEA2") / 60);
      setProgramAR_Q7_observers(getQuestionValue(data, "a2D090000004OVTEA2") + getQuestionValue(data, "a2D090000004OVhEAM"));
      setProgramAR_Q14_actions(getQuestionValue(data, "a2D090000004OVYEA2"));
      setProgramAR_Q18_people(getQuestionValue(data, "a2D9N0000004AT8UAM"));
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramActionReportTeam = async (Id, Pid) => {
    const theID = Id.substr(0, 15);
    const thePID = Pid.substr(0, 15);
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/programActionReportTeam/" +
      theID +
      "/" +
      thePID;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setTeamAR_Q4_hours(getQuestionValue(data, "a2D090000004OVNEA2") / 60);
      setTeamAR_Q7_observers(getQuestionValue(data, "a2D090000004OVTEA2") + getQuestionValue(data, "a2D090000004OVhEAM"));
      setTeamAR_Q14_actions(getQuestionValue(data, "a2D090000004OVYEA2"));
      setTeamAR_Q18_people(getQuestionValue(data, "a2D9N0000004AT8UAM"));
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChangeActionTeam = (e) => {
    const { value } = e.target;
    setProgramActionTeam(value);
    FetchProgramActionReportTeam(value, program);
  };

  const handleInputChangeFilterTeam = (e) => {
    const { value } = e.target;
    setScheduleTeam(value);
    setScheduleGameday("All game days");
    scanListTeam(value);
  };

  const handleInputChangeFilterGameday = (e) => {
    const { value } = e.target;
    setScheduleGameday(value);
    setScheduleTeam("All teams");
    scanListGameday(value);
  };

  function scanListTeam(theTeam) {
    var scanData = programRawGames;
    if (theTeam !== "All teams") {
      scanData = scanData.reduce((filterItems, item) => {
        if (item.team_id === theTeam) {
          filterItems.push(item);
        }
        if (item.opponent_id === theTeam) {
          filterItems.push(item);
        }
        return filterItems;
      }, []);
      setProgramGames(scanData);
    } else {
      setProgramGames(programRawGames);
    }
  }

  function scanListGameday(theGameday) {
    var scanData = programRawGames;
    if (theGameday !== "All game days") {
      scanData = scanData.reduce((filterItems, item) => {
        if (item.game_day_name === theGameday) {
          filterItems.push(item);
        }
        return filterItems;
      }, []);
      setProgramGames(scanData);
    } else {
      setProgramGames(programRawGames);
    }
  }


  const FetchActiveLeagues = async () => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/activeLeagues";
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setActiveLeague(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchTranslation = async (theString) => {
    if (theString === null) {
      return null;
    }
    var translationLanguage = "en";
    if (language === "arab") {
      translationLanguage = "ar";
    }
    var translatedString = "Bad data";
    const theURL =
      "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCIgO9_Oz8NJItmppkfoDX6D59IQniZedA&q=" +
      theString +
      "&target=" +
      translationLanguage;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      translatedString = data.data.translations[0].translatedText;
    } catch (err) {
      console.log(err);
    }
    return translatedString;
  };

  const [searchParams] = useSearchParams();
  const theLanguage = searchParams.get("language");
  const ref = React.useRef(null);

  useEffect(() => {
    setShowNavigation(false);
    FetchActiveLeagues();
    FetchProgramNextEvents("0019N000005Go40QAC");
    i18n.changeLanguage("en");
    setLanguage("en");
    if (theLanguage !== "") {
      i18n.changeLanguage(theLanguage);
      setLanguage(theLanguage);
    }
    ref.current.ownerDocument.body.scrollTop = 0;
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  function parseZone(date) {
    if (date.length <= 10) return parseISO(date);
    return parseISO(date.replace(/[+-]\d\d(:?\d\d)?$|Z$/g, ''));
  }

  function selectLeague(theProgramId, theProgramName) {
    setProgram(theProgramId);
    setProgramName(theProgramName);
    FetchProgramRanking(theProgramId);
    FetchProgramTeams(theProgramId);
    FetchProgramEvents(theProgramId);
    FetchProgramActionReportTotals(theProgramId);
    FetchProgramGames(theProgramId);
    //FetchVoteRank(false);
    setScheduleGameday("All game days");
    setScheduleTeam("All teams");
    setShowNavigation(true);
    showDisplayLeague();
  }

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function lookupGG(theId) {
    if (theId === "a0u090000010VD5AAM") {
      return "Global Goal 1 - No Poverty";
    }
    if (theId === "a0u090000010VDAAA2") {
      return "Global Goal 2 - Zero Hunger";
    }
    if (theId === "a0u090000010VyvAAE") {
      return "Global Goal 3 - Good Health and Well-being";
    }
    if (theId === "a0u090000010VyhAAE") {
      return "Global Goal 4 - Quality Education";
    }
    if (theId === "a0u090000010Vz1AAE") {
      return "Global Goal 5 - Gender Equality";
    }
    if (theId === "a0u090000010VyiAAE") {
      return "Global Goal 6 - Clean Water and Sanitation";
    }
    if (theId === "a0u090000010Vz5AAE") {
      return "Global Goal 7 - Affordable and Clean Energy";
    }
    if (theId === "a0u090000010VzAAAU") {
      return "Global Goal 8 - Decent Work and Economic Growth";
    }
    if (theId === "a0u090000010Vz6AAE") {
      return "Global Goal 9 - Industry, Innovation and Infrastructure";
    }
    if (theId === "a0u090000010VzKAAU") {
      return "Global Goal 10 - Reduced Inequalities";
    }
    if (theId === "a0u090000010VzLAAU") {
      return "Global Goal 11 - Sustainable Cities and Communities";
    }
    if (theId === "a0u090000010VzBAAU") {
      return "Global Goal 12 - Responsible Consumption and Production";
    }
    if (theId === "a0u090000010VzPAAU") {
      return "Global Goal 13 - Climate Action";
    }
    if (theId === "a0u090000010VymAAE") {
      return "Global Goal 14 - Life Below Water";
    }
    if (theId === "a0u090000010VzUAAU") {
      return "Global Goal 15 - Life On Land";
    }
    if (theId === "a0u090000010VzZAAU") {
      return "Global Goal 16 - Peace, Justice and Strong Institutions";
    }
    if (theId === "a0u090000010VywAAE") {
      return "Global Goal 17 - Partnership For The Goals";
    }
    return null;
  }

  function showPickLeague() {
    setPublicStep("PickLeague");
    setShowImpactLeagueHeader(true);
  }

  function showDisplayLeague() {
    setPublicStep("DisplayLeague");
    setShowImpactLeagueHeader(false);
  }

  function showDisplaySchedule() {
    setPublicStep("DisplaySchedule");
    setShowImpactLeagueHeader(false);
  }

  function showDisplayDetails() {
    setPublicStep("DisplayDetails");
    setShowImpactLeagueHeader(false);
  }

  function showDisplayVoting() {
    setPublicStep("DisplayVoting");
    setShowImpactLeagueHeader(false);
  }

  const handleChange = async (event, newValue) => {
    switch (newValue) {
      case "ranking":
        showDisplayLeague();
        break;
      case "schedule":
        showDisplaySchedule();
        break;
      case "league":
        showDisplayDetails();
        break;
      case "vote":
        showDisplayVoting();
        break;

      default:
        setShowNavigation(false);
        showPickLeague();
    }
  };

  function viewTeam(theID) {
    FetchTeam(theID);
    setOpen(true);
  }

  const FetchProgramRanking = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/teamranking/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramRankings(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramGames = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/leagueGames/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramRawGames(data);
      setProgramGames(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramEvents = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/leagueEvents/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramEvents(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramNextEvents = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/nextLeagueEvents/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramNextEvents(data);
      setVotingState(data[0].event_voting);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramTeams = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/programTeams/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramTeams(data);
      if (data.length > 0) {
        setProgramActionTeam(data[0].team_id);
        FetchProgramActionReportTeam(data[0].team_id, data[0].program_id);
      } else {
        setProgramActionTeam("none");
        setTeamAR_Q4_hours(0);
        setTeamAR_Q7_observers(0);
        setTeamAR_Q14_actions(0);
        setTeamAR_Q18_people(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //https://app.lyket.dev/dashboard/like
  const FetchVoteRank = async (showVoteToast) => {
    const lyketSession = localStorage.getItem("lyket-session-id");
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer pt_84b4c080ef6b0e33e91b1d49d9b02b',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Session-Id': lyketSession
      }
    };
    const theURL =
      "https://api.lyket.dev/v1/rank/like-buttons/ILD24";
    const response = await fetch(theURL, requestOptions);
    const votedata = await response.json();
    try {
      setVoteRankings(votedata.data);
      var hasVoted = false;
      Object.entries(votedata.data).forEach(function ([key, val], idx, arr) {
        if (val.attributes.user_has_liked) {
          hasVoted = true;
          if (showVoteToast) {
            toast("Your vote has been counted");
          }
        }
      });
      setUserHasVoted(hasVoted);
    } catch (err) {
      console.log(err);
    }
  };

  function VoteTeamName(teamCode) {
    var teamName = "";
    switch (teamCode) {
      case "0019N000005ha7aQAA":
        teamName = "Sweaty Iconic Sweeties";
        break;
      case "0019N0000063lqSQAQ":
        teamName = "Ball Kickers";
        break;
      case "0019N000005haWbQAI":
        teamName = "Solidarity Sirens";
        break;
      case "0019N000005haMgQAI":
        teamName = "Mantis Shrimp";
        break;
      case "0019N0000068u2VQAQ":
        teamName = "Detroit Dames";
        break;
    }
    return teamName;
  };
  function VoteTeamPurpose(teamCode) {
    var teamName = "";
    switch (teamCode) {
      case "0019N000005ha7aQAA":
        teamName = "Believes real impact comes from consistent support and neighborly care. Partnering with Ruth Ellis Center.";
        break;
      case "0019N0000063lqSQAQ":
        teamName = "Champion mental health through healthcare packages & healthy living initiatives, partnering with The Clean Love Project.";
        break;
      case "0019N000005haWbQAI":
        teamName = "A group of women & non-binary athletes formed on DCFC leagues, champion inclusive soccer via Women's Plus. Part of @womenplussoccerdet.";
        break;
      case "0019N000005haMgQAI":
        teamName = "Tackles soccer equality (women & non-binary) through social events. Part of @womenplussoccerdet";
        break;
      case "0019N0000068u2VQAQ":
        teamName = "A free agent force, rewriting the rulebook on women's soccer.";
        break;
    }
    return teamName;
  };

  const FetchTeam = async (Id) => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/appTeam/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      data[0].introduce_the_team_club__c = await FetchTranslation(
        data[0].introduce_the_team_club__c
      );
      data[0].global_goal_action__c = await FetchTranslation(
        data[0].global_goal_action__c
      );
      data[0].global_goal_impact__c = await FetchTranslation(
        data[0].global_goal_impact__c
      );
      data[0].global_goal_why__c = await FetchTranslation(
        data[0].global_goal_why__c
      );
      //console.log(data[0]);
      setTeamvalue(data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function disableVoting(state) {
    if (!votingState) {
      return "vote-is-disabled";
    }

    if (userHasVoted) {
      if (!state) {
        return "vote-is-disabled";
      }
    }

    return "";
  }

  const headCells = [
    {
      id: "team_name",
      numeric: false,
      disablePadding: false,
      label: "Team",
    },
    {
      id: "action_p",
      numeric: true,
      disablePadding: false,
      label: "AC",
    },
    {
      id: "style_p",
      numeric: true,
      disablePadding: false,
      label: "PP",
    },
    {
      id: "spirit_p",
      numeric: true,
      disablePadding: false,
      label: "PS",
    },
    {
      id: "game_p",
      numeric: true,
      disablePadding: false,
      label: "FB",
    },
    {
      id: "crowd_p",
      numeric: true,
      disablePadding: false,
      label: "CR",
    },
    {
      id: "total_p",
      numeric: true,
      disablePadding: false,
      label: "TP",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ m: 0, p: 0, pb: 1, fontSize: "10px" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ ml: 0 }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  function setHeaderbackground(context) {
    if (context = "PickLeague") {
      return "assets/app_top_image.jpg";
    } else {
      return "assets/App_top_image2.png";
    }
  }

  return (

    <ThemeProvider theme={ImpactLeagueTheme}>

      <CssBaseline />
      <PublicLeagueLayout>
        <div>
          <Drawer
            PaperProps={{
              sx: { width: "100%", pr: 0 },
            }}
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box sx={{ pt: 2, pr: 2 }} display="flex" justifyContent="space-between">
              <Typography
                align="left"
                sx={{ mt: 0, ml: 1, pb: 0 }}
                variant="h6"
              >
                &nbsp;
              </Typography>
              <CloseIcon
                fontSize="large"
                align="right"
                onClick={() => setOpen(false)}
              />
            </Box>
            <Typography
              align="center"
              sx={{ mt: 0, ml: 0, pb: 0 }}
              variant="h5"
            >
              {t("public_team")}
            </Typography>
            <Typography sx={{ p: 2, m: 0, pb: 0, pt: 0, fontWeight: 900 }} align="center" variant="h4">
              {teamvalue.name || ""}
            </Typography>
            <Button
              style={{
                color: "#000"
              }}
              variant='text'
              href="www.instagram.com/sensational_league_detroit/"
              sx={{ p: 0, pb: 2 }}
            >
              {teamvalue.instragram_handle__c || "@sensational_league_detroit"}
            </Button>
            {teamvalue.primary_photo__c ? (
              <NiceCardMedia
                component="img"
                sx={{ width: "100%", pb: 2 }}
                image={
                  "https://agxpxlknzr.cloudimg.io/" +
                  teamvalue.primary_photo__c +
                  "?width=500&height=500"
                }
                alt="Sensational League"
              />
            ) : (
              <div></div>
            )}
            <div align="center">
              <NiceCardMedia
                id="team-gg"
                component="img"
                sx={{ width: 130, pb: 2 }}
                image={flipGG(teamvalue.global_goal__c)}
                alt="GGWCUP Ball"
              />
            </div>
            <div align="center">
              <Typography sx={{ p: 0, m: 0, pb: 0, fontWeight: 300 }} variant="h6">
                {t("public_bio")} {teamvalue.name}
              </Typography>
              <Typography sx={{ p: 0, m: 0, pb: 2, fontWeight: 300 }} variant="body">
                {teamvalue.introduce_the_team_club__c || "..."}
              </Typography>
            </div>
            <div align="center">
              <Typography sx={{ p: 0, m: 0, pt: 2, fontWeight: 300 }} variant="body2">
                {t("public_why")}
              </Typography>
              <Typography sx={{ p: 0, m: 0, pb: 2, fontWeight: 300 }} variant="body">
                {teamvalue.global_goal_why__c || "..."}
              </Typography>
            </div>
            <div align="center">
              <Typography sx={{ p: 0, m: 0, pt: 2, fontWeight: 300 }} variant="body2">
                {t("public_how")}
              </Typography>
              <Typography sx={{ p: 0, m: 0, pb: 0, fontWeight: 300 }} variant="body">
                {teamvalue.global_goal_action__c || "..."}
              </Typography>
            </div>
            <div align="center">
              <Typography sx={{ p: 0, m: 0, pt: 2, fontWeight: 300 }} variant="body2">
                {t("public_success")}
              </Typography>
              <Typography sx={{ p: 0, m: 0, pb: 2, fontWeight: 300 }} variant="body">
                {teamvalue.team_success__c || "..."}
              </Typography>
            </div>
            <div align="center">
              <Typography sx={{ p: 0, m: 0, pt: 2, fontWeight: 300 }} variant="body2">
                {t("public_objective")}
              </Typography>
              <Typography sx={{ p: 0, m: 0, pb: 2, fontWeight: 300 }} variant="body">
                {teamvalue.team_objective__c || "..."}
              </Typography>
            </div>
          </Drawer>
        </div>
        <Container maxWidth="lg" sx={{ p: 0 }}>
          {showImpactLeagueHeader ? (
            <Box sx={{ p: 2, pt: 1, pb: 2, height: 200, backgroundImage: 'url(assets/app_top_image.jpg)', backgroundPosition: "center", backgroundSize: "cover", flexGrow: 1, textAlign: "right" }}>
              <Button
                style={{
                  backgroundColor: "#FFF",
                  color: "#000"
                }}
                variant='contained'
                href="/gate"
                sx={{ boxShadow: 15, }}
              >
                log-in
              </Button>
            </Box>
          ) : (<Box sx={{ p: 2, pt: 1, pb: 2, height: 200, backgroundImage: 'url(assets/App_top_image2.png)', backgroundPosition: "center", backgroundSize: "cover", flexGrow: 1, textAlign: "right" }}>
          </Box>
          )}
          <Box sx={{ pl: 2, pr: 2, pb: 7 }} ref={ref}>
            <TabContext value={publicStep}>
              <TabPanel value="PickLeague" sx={{ p: 1 }}>
                {/*                 
                <FormControl size="small" sx={{ pt: 4, pb: 1, minWidth: 100 }}>
                  <Select
                    labelId="language-simple-select-label"
                    id="i18n1"
                    name="i18n1"
                    value={language || "en"}
                    onChange={handleLanguageChange}
                  >
                    <MenuItem key="1" value="en">
                      English
                    </MenuItem>
                  </Select>
                </FormControl>
 */}
                <Typography sx={{
                  pt: 1,
                  pb: 2,
                  fontWeight: 900,
                  lineHeight: 1
                }} variant="h2">
                  {t("public_welcome")}
                </Typography>
                {activeLeague.map((row) => (
                  <Box
                    id={row.sfid}
                    sx={{
                      height: "40px",
                      backgroundColor: "green",
                      flexGrow: 1,
                      borderRadius: "15px",
                      paddingTop: "8px"
                    }}
                    onClick={() =>
                      selectLeague(row.sfid, row.name, row.program_sports__c)
                    }
                  >
                    <WhiteTypography sx={{}} variant="body">
                      {t("public_tournament")}
                    </WhiteTypography>
                    <Divider sx={{ pt: 2, pb: 2, borderColor: "#000" }} />
                    <Avatar
                      name="logo"
                      size="175"
                      src={
                        "https://images.squarespace-cdn.com/content/v1/63f8eb5a81ef74363b09bd4d/8bf709d1-e9cf-4c73-b4e2-298fd8b4a3d6/DCFC-Crest-2021-no-star.png"
                      }
                    />
                    <Typography sx={{ pt: 1, pb: 2, fontWeight: 900, lineHeight: 1 }} variant="h4">
                      {row.name}
                    </Typography>
                    {programNextEvents.map((events) => (
                      <Box>
                        <Typography sx={{ pt: 0, pb: 1, fontWeight: 700, lineHeight: 1 }} variant="body1">
                          Huge thanks to all teams for an amazing season.
                        </Typography>
                        <Typography sx={{ pt: 0, pb: 1, fontWeight: 300, lineHeight: 1 }} variant="body1">
                          Congrats to SIS & Ball Kickers for sharing first place.
                        </Typography>
                        <Typography sx={{ pt: 0, pb: 1, fontWeight: 300, lineHeight: 1 }} variant="body1">
                          See you next season!
                        </Typography>
                        <Typography sx={{ pt: 0, pb: 0, fontWeight: 300, lineHeight: 1 }} variant="body1">
                          Sign-up date will be announced here and on the Instagram handle below.
                        </Typography>
                        <Button
                          style={{
                            color: "#000"
                          }}
                          variant='text'
                          href="https://www.instagram.com/sensational_league_detroit"
                          sx={{ pt: 2 }}
                        >
                          @sensational_league_detroit
                        </Button>
                      </Box>
                    ))}

                  </Box>
                ))}
              </TabPanel>
              <TabPanel sx={{ p: 0, m: 0 }} value="DisplayLeague">
                {" "}
                <Typography sx={{ pt: 3, pb: 2, fontWeight: 900 }} variant="h5">
                  {programName}
                </Typography>
                <div>
                  <Typography
                    align="left"
                    sx={{ mt: 0, ml: 0, pb: 2 }}
                    variant="h5"
                  >
                    {t("public_ranking")}
                  </Typography>
                  <TableContainer>
                    <Table
                      sx={{ overflow: "hidden" }}
                      size="medium"
                      aria-label="a dense table"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {stableSort(
                          programRankings,
                          getComparator(order, orderBy)
                        ).map((row) => {
                          return (
                            <TableRow
                              key={row.team_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                }
                              }}
                            >
                              <TableCell
                                sx={{ m: 0, p: 0, pt: 1, pb: 1 }}
                                component="th"
                                scope="row"
                              >
                                <Box sx={{ pt: 0 }} display="flex" justifyContent="space-between">
                                  <Avatar
                                    name={row.name}
                                    size="25"
                                    src={flipGG(row.team_gg)}
                                  />
                                  &nbsp;
                                  <MuiLink
                                    style={{
                                      textAlign: "left",
                                      whiteSpace: "normal",
                                      //textOverflow: "ellipsis",
                                      width: "95px",
                                      display: "block",
                                      overflow: "visible",
                                    }}
                                    component="button"
                                    onClick={() => viewTeam(row.team_id)}
                                    variant="body1"
                                    underline="hover"
                                    sx={{ fontSize: "13px", lineHeight: "13px", fontWeight: 600, color: "#000" }}
                                  >
                                    {row.team_name}
                                  </MuiLink>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                                {row.action_p}
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                                {row.style_p}
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                                {row.spirit_p}
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                                {row.game_p}
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                                {row.crowd_p}
                              </TableCell>
                              <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                                {row.total_p}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <NiceTextField
                    id="rank_intro"
                    variant="standard"
                    multiline
                    InputProps={{ disableUnderline: true }}
                    value={t("public_ranking_intro")}
                  />
                  <NiceTextField
                    id="rank_action"
                    variant="standard"
                    multiline
                    label={t("public_action_point")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_action_point_text")}
                  />

                  <NiceTextField
                    id="rank_purpose"
                    variant="standard"
                    multiline
                    label={t("public_purpose_point")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_purpose_point_text")}
                  />
                  <NiceTextField
                    id="rank_spirit"
                    variant="standard"
                    multiline
                    label={t("public_spirit_point")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_spirit_point_text")}
                  />

                  <NiceTextField
                    id="rank_game"
                    variant="standard"
                    multiline
                    label={t("public_game_point")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_game_point_text")}
                  />
                  <NiceTextField
                    id="rank_followers"
                    variant="standard"
                    multiline
                    label={t("public_followers_point")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_followers_point_text")}
                  />
                  <NiceTextField
                    id="rank_winner"
                    variant="standard"
                    multiline
                    label={t("public_overall_winner")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_overall_winner_text")}
                  />
                  <Typography align="left" variant="body2">
                    <Link
                      sx={{ ml: 1, mb: 1 }}
                      align="left"
                      target="_blank"
                      rel="noopener"
                      href="https://www.impact-league.com/how-we-play"
                    >
                      {t("public_action_point_link")}
                    </Link>
                  </Typography>
                  <NiceTextField
                    id="rank_style"
                    variant="standard"
                    multiline
                    label={t("public_style_point")}
                    InputProps={{ disableUnderline: true }}
                    value={t("public_style_point_text")}
                  />
                </div>
              </TabPanel>
              <TabPanel sx={{ p: 0, m: 0 }} value="DisplaySchedule">
                {" "}
                <Typography sx={{ pt: 3, pb: 2, fontWeight: 900 }} variant="h5">
                  {programName}
                </Typography>
                <div>
                  <Typography
                    align="left"
                    sx={{ mt: 0, ml: 0, pb: 2 }}
                    variant="h5"
                  >
                    {t("public_schedule")}
                  </Typography>
                  <TableContainer>
                    <TableBody>
                      <TableRow
                        key="522555"
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell
                          sx={{ m: 0, p: 0, pb: 1 }}
                          style={{
                            whiteSpace: "nowrap",
                            //textOverflow: "ellipsis",
                            width: "182px",
                            border: "0px",
                            display: "block",
                            overflow: "hidden",
                          }}
                          align="left"
                        >
                          <Typography
                            sx={{ pt: 1, pb: 0 }}
                            variant="body2"
                            align="left"
                          >
                            <NiceSelectFormControl sx={{ m: 0 }}>
                              <InputLabel id="demo-simple-select-label-dm">
                                {t("public_game_day_filter")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-dm"
                                id="action_team"
                                label="Action Team"
                                value={scheduleGameday || ""}
                                name="Action_team"
                                onChange={handleInputChangeFilterGameday}
                              >
                                <MenuItem
                                  key="6464sss6ddd166"
                                  value="All game days"
                                >
                                  All game days
                                </MenuItem>
                                {programEvents.map((row) => (
                                  <MenuItem key={row.sfid} value={row.event_day}>
                                    {row.event_day}
                                  </MenuItem>
                                ))}
                              </Select>
                            </NiceSelectFormControl>
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ m: 0, p: 0, pb: 1 }} align="right">
                          <Typography
                            sx={{ pt: 1, pb: 0 }}
                            variant="body2"
                            align="left"
                            style={{
                              whiteSpace: "nowrap",
                              //textOverflow: "ellipsis",
                              width: "182px",
                              display: "block",
                              overflow: "hidden",
                            }}
                          >
                            <NiceSelectFormControl sx={{ m: 0 }}>
                              <InputLabel id="demo-simple-select-label-dm">
                                {t("public_team_filter")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-dm"
                                id="action_team"
                                label="Action Team"
                                value={scheduleTeam || ""}
                                name="Action_team"
                                onChange={handleInputChangeFilterTeam}
                              >
                                <MenuItem key="64646rrreee166" value="All teams">
                                  All teams
                                </MenuItem>
                                {programTeams.map((row) => (
                                  <MenuItem key={row.sfid} value={row.team_id}>
                                    {row.team_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </NiceSelectFormControl>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </TableContainer>
                  <TableContainer>
                    <TableBody>
                      {programGames.map((row) => {
                        return (
                          <TableRow
                            key={row.sfid}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Box sx={{ pt: 2 }} >
                              <Typography sx={{ pt: 0, pb: 0 }} variant="body" >
                                {row.game_day_name}:
                              </Typography>
                              <Typography sx={{ pt: 0, pb: 0, ml: 1, fontWeight: 600 }} variant="body" >
                                {format(parseJSON(parseZone(row.game_date)), "EEEE do")};
                              </Typography>

                            </Box>
                            <Box sx={{ pt: 0 }} display="flex" justifyContent="space-between">
                              <Box sx={{ pt: 0, width: "100%" }} display="flex" justifyContent="space-between">
                                <TableCell
                                  sx={{ m: 0, p: 0, pt: 1, pb: 1, width: "175px" }}
                                  component="th"
                                  scope="row"
                                >
                                  <Box sx={{ pt: 0 }} display="flex" justifyContent="space-between">
                                    <Avatar
                                      name={row.name}
                                      size="25"
                                      src={flipGG(row.team_gg)}
                                    />
                                    &nbsp;
                                    <MuiLink
                                      style={{
                                        textAlign: "left",
                                        whiteSpace: "normal",
                                        //textOverflow: "ellipsis",
                                        width: "145px",
                                        display: "block",
                                        overflow: "visible",
                                      }}
                                      component="button"
                                      onClick={() => viewTeam(row.team_id)}
                                      variant="body1"
                                      underline="hover"
                                      sx={{ fontSize: "13px", lineHeight: "13px", fontWeight: 600, color: "#000" }}
                                    >
                                      {row.team_name}
                                    </MuiLink>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{ m: 0, p: 0, pt: 1, pb: 1, width: "175px" }}
                                  component="th"
                                  scope="row"
                                >
                                  <Box sx={{ pt: 0 }} display="flex" justifyContent="space-between">
                                    <MuiLink
                                      style={{
                                        textAlign: "right",
                                        whiteSpace: "normal",
                                        //textOverflow: "ellipsis",
                                        width: "145px",
                                        display: "block",
                                        overflow: "visible",
                                      }}
                                      component="button"
                                      onClick={() => viewTeam(row.opponent_id)}
                                      variant="body1"
                                      underline="hover"
                                      sx={{ fontSize: "13px", lineHeight: "13px", fontWeight: 600, color: "#000" }}
                                    >
                                      {row.opponent_name}
                                    </MuiLink>
                                    &nbsp;
                                    <Avatar
                                      name={row.opponent_name}
                                      size="25"
                                      src={flipGG(row.opponent_gg)}
                                    />

                                  </Box>
                                </TableCell>

                              </Box>
                            </Box>
                            <Divider sx={{ pt: 0, pb: 1, borderColor: "#000" }} />
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </TableContainer>
                </div>
              </TabPanel>
              <TabPanel sx={{ p: 0, m: 0 }} value="DisplayDetails">
                {" "}
                <Typography sx={{ pt: 3, pb: 2, fontWeight: 900 }} variant="h5">
                  {programName}
                </Typography>
                <div>
                  <Typography
                    align="left"
                    sx={{ mt: 0, ml: 0, pb: 2, pl: 2 }}
                    variant="h5"
                  >
                    {t("public_details")}
                  </Typography>
                  <TableContainer></TableContainer>
                </div>
                <div>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title={programName + " " + t("public_ar_program")} />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_hours")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q4_hours}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_observers")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q7_observers}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_actions")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q14_actions}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_people")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q18_people}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <br />
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title={"Global " + t("public_ar_program")} />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_hours")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q4_hours + 123.5}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_observers")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q7_observers + 12801}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_actions")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q14_actions + 57}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_people")}
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q18_people + 5751}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <br />

                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title={t("public_ar_team")} />
                    <CardContent>
                      <NiceSelectFormControl fullWidth>
                        <InputLabel id="demo-simple-select-label-dm">
                          {t("public_team_filter")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-dm"
                          id="action_team"
                          label="Action Team"
                          value={programActionTeam || ""}
                          name="Action_team"
                          onChange={handleInputChangeActionTeam}
                        >
                          {programTeams.map((row) => (
                            <MenuItem key={row.sfid} value={row.team_id}>
                              {row.team_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </NiceSelectFormControl>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_hours")}
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q4_hours}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_observers")}
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q7_observers}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_actions")}
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q14_actions}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                {t("public_ar_people")}
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q18_people}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              </TabPanel>
              <TabPanel sx={{ p: 0, m: 0 }} value="DisplayVoting">
                {" "}
                <Typography sx={{ pt: 3, pb: 2, fontWeight: 900 }} variant="h5">
                  {programName}
                </Typography>
                <Typography
                  align="left"
                  sx={{ mt: 0, ml: 0, pb: 2, pl: 2 }}
                  variant="h5"
                >
                  {t("public_vote_headline")}
                </Typography>
                <Typography
                  align="left"
                  sx={{ mt: 0, ml: 0, pb: 2, pl: 2 }}
                  variant="body1"
                >
                  {t("public_vote_details")}
                </Typography>
                <Provider apiKey="pt_84b4c080ef6b0e33e91b1d49d9b02b">
                  {voteRankings.map((row) => (
                    <Box class={disableVoting(row.attributes.user_has_liked)}>
                      <NiceCardMedia
                        component="img"
                        sx={{ width: "100%", pb: 2 }}
                        image={'teams/' + row.id + '.jpg'}
                        alt={row.id}
                      />
                      <div class="vote-name">
                        {VoteTeamName(row.attributes.tags[0])}
                      </div>
                      <div class="vote-purpose">
                        {VoteTeamPurpose(row.attributes.tags[0])}
                      </div>
                      <div class="vote-button">
                        <LikeButton
                          namespace={row.attributes.namespace}
                          id={row.id}
                          onPress={() => FetchVoteRank(true)}
                        />
                      </div>
                    </Box>
                  ))}
                </Provider>
                <NiceTextField
                  id="vote_rules"
                  variant="standard"
                  sx={{ mb: 0 }}
                  multiline
                  label={t("public_voting_rule")}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_voting_rule_text1")}
                />
                <NiceTextField
                  id="vote_rules_text2"
                  variant="standard"
                  sx={{ mb: 0, mt: 0 }}
                  multiline
                  InputProps={{ disableUnderline: true }}
                  value={t("public_voting_rule_text2")}
                />
                <NiceTextField
                  id="vote_rules_text4"
                  variant="standard"
                  sx={{ mb: 0, mt: 0 }}
                  multiline
                  InputProps={{ disableUnderline: true }}
                  value={t("public_voting_rule_text3")}
                />
                <NiceTextField
                  id="vote_rules_text3"
                  variant="standard"
                  sx={{ mb: 0, mt: 0 }}
                  multiline
                  InputProps={{ disableUnderline: true }}
                  value={t("public_voting_rule_text4")}
                />
                <NiceTextField
                  id="vote_rules_text4"
                  variant="standard"
                  multiline
                  sx={{ mb: 0, mt: 0 }}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_voting_rule_text5")}
                />
                <NiceTextField
                  id="vote_rules_text5"
                  variant="standard"
                  multiline
                  sx={{ mb: 0, mt: 0 }}
                  InputProps={{ disableUnderline: true }}
                  value={t("public_voting_rule_text6")}
                />

              </TabPanel>

            </TabContext>
            {showNavigation === true ? (
              <div>
                <Paper
                  sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                  elevation={3}
                >
                  <Box sx={{ pt: 2, pb: 0 }}>
                    <img src="assets/sensational_league_logo.png" alt="Sensational League Close The Gap" height="40px"></img>
                  </Box>
                  <BottomNavigation
                    value={publicStep}
                    showLabels
                    onChange={handleChange}
                  >
                    <BottomNavigationAction
                      label={t("public_switch")}
                      value="switch"
                    />
                    <BottomNavigationAction
                      label={t("public_ranking")}
                      value="ranking"
                    />
                    <BottomNavigationAction
                      label={t("public_schedule")}
                      value="schedule"
                    />
                    <BottomNavigationAction
                      label={t("public_details")}
                      value="league"
                    />
                    <BottomNavigationAction
                      label={"Vote"}
                      value="vote"
                    />

                  </BottomNavigation>
                </Paper>
              </div>
            ) : (
              <div />
            )}
          </Box>
        </Container>
      </PublicLeagueLayout>
    </ThemeProvider >
  );
};

export default PublicLeague;
